<template>
  <div class="container">
      <b-modal id="confirmationModal" title="Thank You">
      Your entries have been submitted for review.  We will make sure your choices look great in the app.
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      modal-class="my-second-class"
      ref="my-modal"
      hide-footer
      hide-header
    >
      <div class="d-block text-center">
        <h3>{{ modalTitle }}</h3>
        <p>Run mouse pointer or finger across color board to change.</p>
        <p>TAP/CLICK on color board to choose displayed color.</p>
        <vue-color-picker-board
          id="board1"
          :width="400"
          :height="150"
          :defaultColor="current_color"
          @onSelection="colorSelection"
        >
        </vue-color-picker-board>
      </div>
      <b-button class="mt-3" :style="{ backgroundColor: current_color, color: current_color == '#FFFFFF' ? 'black' : 'white' }" block
        >Color Choice</b-button
      >
      <b-button class="mt-3" variant="outline-primary" block @click="hideModal"
        >Finished</b-button
      >
    </b-modal>
    
    <br />
    <h2 v-if="in_review" style="background: #003E69; color: #F0BF48;" class="text-center my-2">Your submission is in the review</h2>
    <h2>APP CUSTOMIZATION</h2>
    <p>
      Enter color hex code or TAP/CLICK on a colored brush button to choose
      color for app components based on your unique brand.
    </p>
    <br />
    <div class="row">
      <div class="col-sm">
        <h4>Navbar Color</h4>
        <p>
          The color for the top app navigation bar. Choose a color that will
          look good as backgroud for your logo
        </p>
        <b-form inline @submit.prevent>
          <b-form-input
            @input="hexNavChanged($event)"
            id="inline-form-input-name"
            v-model="input_nav_bar_color"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="#759791"
          ></b-form-input>
          <p></p>
          <b-button
            id="show-btn"
            @click="showModal(0)"
            :style="{ backgroundColor: navbar_color }"
            ><b-icon
              icon="brush"
              aria-hidden="true"
              :style="getBrushColor(navbar_color)"
            ></b-icon
          ></b-button>
        </b-form>
      </div>
      <div class="col-sm">
        <h4>Primary Button Color</h4>
        <p>
          This is the color which we will apply to most buttons. A color that
          looks good with white font is preferred.
        </p>
        <b-form inline @submit.prevent>
          <b-form-input
            @input="hexPrimaryChanged($event)"
            id="inline-form-input-primary"
            v-model="input_primary_color"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="#003E69"
          ></b-form-input>
          <p></p>
          <b-button
            id="show-btn-2"
            @click="showModal(1)"
            :style="{ backgroundColor: primary_color }"
          >
            <b-icon
              icon="brush"
              aria-hidden="true"
              :style="getBrushColor(primary_color)"
            ></b-icon
          ></b-button>
        </b-form>
      </div>
      <div class="col-sm">
        <h4>Secondary Color</h4>
        <p>
          This is the color which we will apply to text on bottom tab bar. A
          color that looks good with text is preferred.
        </p>
        <b-form inline @submit.prevent>
          <b-form-input
            @input="hexSecondaryChanged($event)"
            id="inline-form-input-secondary"
            v-model="input_secondary_color"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="#AE6457"
          ></b-form-input>
          <p></p>
          <b-button
            id="show-btn-3"
            @click="showModal(2)"
            :style="{ backgroundColor: secondary_color }"
          >
            <b-icon
              icon="brush"
              aria-hidden="true"
              :style="getBrushColor(secondary_color)"
            ></b-icon
          ></b-button>
        </b-form>
      </div>
    </div>
    <br />
    <br />
    <b-row align-v="center">
      <b-col :cols="horizontal_logo_url == '' ? 12 : 6">
        <h3>Upload Horizontal Logo</h3>
        <p>
          Must be a .png image with transparent background. Best size = 900px
          width, 200px height
        </p>
        <div class="dropbox">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event);
              fileCount = $event.target.files.length;
            "
            accept="image/*"
            class="input-file"
          />
          <p v-if="isInitial">
            Drag your logo image file here<br />
            or click to browse
          </p>
          <p v-if="isSaving">Your image shows to right against your navbar color</p>
        </div>
      </b-col>
      <b-col cols="6" >
        <b-card
          v-if="horizontal_logo_url != ''"
          :style="'background-color: ' + navbar_color + ';'"
          class="h-100"
        >
          <b-img-lazy :src="horizontal_logo_url" fluid />
        </b-card>

        <h4 class="text-center mt-3">Firm Code: {{ firm_code }}</h4>
      </b-col>
    </b-row>
    <br />
     <b-toast id="png-toast" title="Wrong Image Type" static no-auto-hide>
      The image must be a png with transparent background.
    </b-toast>
    <b-button
      block
      @click="submitForReview"
      variant="primary"
      :disabled="disableSubmit"
      >Submit for Review <b-spinner small v-if="submitting"
    /></b-button>
  </div>
</template>

<script
  type="text/javascript"
  src="node_modules/vue-color-picker-board/dist/vue-color-picker-board.min.js"
></script>
<script>
import Vue from "vue";
import { BIconBrush } from "bootstrap-vue";
import VueColorPickerBoard from "vue-color-picker-board";
import FileReader from "filereader";
Vue.use(VueColorPickerBoard);

export default {
  data() {
    return {
      modalTitle: "Choose Navbar Color",
      modal_bc: "#000000!important",
      pickedBoard: 0,
      current_color: "#FFFFFF",
      input_nav_bar_color: "",
      navbar_color: "#759791",
      input_primary_color: "",
      primary_color: "#003E69",
      input_secondary_color: "",
      secondary_color: "#AE6457",
      horizontal_logo: null,
      horizontal_logo_url: "",
      modal_title: "Choose your navbar color",
      authorizing: false,
      complianceUrl: "",
      settingCompliance: false,
      isInitial: true,
      submitting: false,
      formObject: {},
      isSaving: false,
      uploadFieldName: "",
      disableSubmit: false,
      firm_code: "",
      in_review: false
    };
  },
  components: {
    VueColorPickerBoard,
  },
  methods: {
    async importFirm() {
      console.log("created hit");
      var custURL = this.$store.state.apiURL + "/customization";
      console.log("cust url");
      console.log(custURL);
      try {
        let response = await this.$http.get(
          this.$store.state.apiURL + "/customization",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.input_nav_bar_color = response.data.navbar_color;
        this.input_primary_color = response.data.primary_color;
        this.input_secondary_color = response.data.secondary_color;
        this.horizontal_logo_url = response.data.horizontal_logo;

        console.log("GET firm in mounted() succeeded:  ", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
    showModal(bparam) {
      console.log(bparam);
      if (bparam == 0) {
        this.pickedBoard = 0;
        this.current_color = this.navbar_color;
        this.modalTitle = "Choose Navbar Color";
      } else if (bparam == 1) {
        this.pickedBoard = 1;
        this.current_color = this.primary_color;
        this.modalTitle = "Choose Primary Color";
      } else if (bparam == 2) {
        this.pickedBoard = 2;
        this.current_color = this.secondary_color;
        this.modalTitle = "Choose Secondary Color";
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    colorSelection(selection) {
      console.log(selection);
      this.current_color = selection;
      if (this.pickedBoard == 0) {
        this.input_nav_bar_color = selection;
        this.navbar_color = selection;
      } else if (this.pickedBoard == 1) {
        this.input_primary_color = selection;
        this.primary_color = selection;
      } else {
        this.input_secondary_color = selection;
        this.secondary_color = selection;
      }
    },
    hexNavChanged($event) {
      if (
        this.input_nav_bar_color.length == 7 &&
        this.input_nav_bar_color.includes("#")
      ) {
        this.navbar_color = this.input_nav_bar_color;

        if (
          this.input_primary_color.length == 7 &&
          this.input_primary_color.includes("#") &&
          this.input_secondary_color.length == 7 &&
          this.input_secondary_color.includes("#")
        ) {
          this.disableSubmit = false;
        }
      } else {
        this.disableSubmit = true;
      }
    },
    hexPrimaryChanged($event) {
      if (
        this.input_primary_color.length == 7 &&
        this.input_primary_color.includes("#")
      ) {
        this.primary_color = this.input_primary_color;

        if (
          this.input_nav_bar_color.length == 7 &&
          this.input_nav_bar_color.includes("#") &&
          this.input_secondary_color.length == 7 &&
          this.input_secondary_color.includes("#")
        ) {
          this.disableSubmit = false;
        }
      } else {
        this.disableSubmit = true;
      }
    },
    hexSecondaryChanged($event) {
      if (
        this.input_secondary_color.length == 7 &&
        this.input_secondary_color.includes("#")
      ) {
        this.secondary_color = this.input_secondary_color;

        if (
          this.input_primary_color.length == 7 &&
          this.input_primary_color.includes("#") &&
          this.input_nav_bar_color.length == 7 &&
          this.input_nav_bar_color.includes("#")
        ) {
          this.disableSubmit = false;
        }
      } else {
        this.disableSubmit = true;
      }
    },
    colorSelectionBar() {
      this.pickedBoard = 0;
      this.modalTitle = "Choose your primary button color";
    },
    colorSelectionPrimary() {
      this.pickedBoard = 1;
      this.modalTitle = "Choose your primary button color";
    },
    colorSelectionSecondary() {
      this.pickedBoard = 2;
      this.modalTitle = "Choose your secondary color";
    },
    filesChange(event) {
      console.log(event.target.files[0]);
      this.horizontal_logo = event.target.files[0];
      this.createImage(event.target.files[0]);
       this.$bvToast.hide('png-toast')
    },
    async submitForReview() {
       this.$bvToast.hide('png-toast')
      this.submitting = true;

      const re = /(?:\.([^.]+))?$/;

      const ext = re.exec(this.horizontal_logo.name)[1];
     
      var d = new Date();
      var n = d.toString();
      if (ext != "png"){
       this.$bvToast.show('png-toast')
       this.submitting = false;
      } else {
        console.log(this.horizontal_logo.name);
        var imageFullName = n + this.horizontal_logo.name;
      try {
        let response = await this.$http.post(
        this.$store.state.apiURL + "/media",
        {
          name: imageFullName,
          description: "user photo",
          media_type: "image",
          file_extension: ext,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );
      console.log(response.data);
      var options = {
        headers: {
          "Content-Type": "image/" + ext,
          "x-amz-acl": "public-read",
        },
      };

      await this.$http.put(
        response.data.presigned_url,
        this.horizontal_logo,
        options
      );

      let confirmResponse = await this.$http.post(
        this.$store.state.apiURL + "/media/" + response.data.id,
        {
          confirm_action: "url",
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );
      this.horizontal_logo_url = confirmResponse.data.url;

      this.formObject = {
        navbar_color: this.input_nav_bar_color,
        primary_color: this.input_primary_color,
        secondary_color: this.input_secondary_color,
        horizontal_logo: this.horizontal_logo_url,
      };

      console.log("Submitting form:  ", this.formObject);

      try {
        await this.$http.put(
          this.$store.state.apiURL + "/customization",
          this.formObject,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        let res = await this.$http.get(
          this.$store.state.apiURL + "/customization",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.in_review = res.data.in_review;

      this.$bvModal.show("confirmationModal");
      } catch (error) {
        this.submitting = false;
        this.handleError(error);
      }

      this.submitting = false;
 
        
      } catch (error) {
        this.submitting = false;
        this.handleError(error)
      }
      }
      
    },
    ok() {
      this.$bvModal.hide("confirmationModal");
    },
    createImage(file) {
      let reader = new window.FileReader();
      reader.onload = (event) => {
        this.horizontal_logo_url = event.target.result;
        this.isSaving = true;
      };
      reader.readAsDataURL(file);
    },
    getBrushColor(color) {
      return color == "#FFFFFF" ? "color: black" : "color: white";
    },
  },

  async mounted() {
    try {
      let response = await this.$http.get(
        this.$store.state.apiURL + "/customization",
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );

      this.input_nav_bar_color = response.data.navbar_color;
      this.navbar_color = response.data.navbar_color;
      this.input_primary_color = response.data.primary_color;
      this.primary_color = response.data.primary_color;
      this.input_secondary_color = response.data.secondary_color;
      this.secondary_color = response.data.secondary_color;
      this.horizontal_logo = response.data.horizontal_logo;
      this.firm_code = response.data.firm_code;

      console.log("GET firm in mounted() succeeded:  ", response.data);
    } catch (error) {
      this.handleError(error);
    }
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss";
.modal-content {
  background: #e8e8e8 !important;
  color: black !important;
}
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */

  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
